
import common from './common';

const CountUp = common.CountUp;

const selectors = {
	count : '[data-count]'
}

const dataAttr = 'data-count';

export const initInstance = function(el) {
	const countElems = [...el.querySelectorAll(selectors.count)];
	const counts = countElems.map((elem) => {
		const countTo = elem.getAttribute(dataAttr);
		return new CountUp(elem, countTo);
	});

	const observer = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			if (entry.intersectionRatio <= 0) return;
			observer.unobserve(entry.target);
			setTimeout(() => counts.map((count) => count.start()), 600);
		});
	});
	observer.observe(el);
}
